import React from "react";
import type { FC } from "react";
import type {
  AutocompleteApi,
  AutocompleteCollection,
  AutocompleteState,
} from "@algolia/autocomplete-core";
import style from "../Autocomplete.module.css";
import type { AutocompleteItem } from "../types";
import { QuerySuggestion } from "./QuerySuggestion";
import { Item } from "./Item";

type ResultListProps = {
  listTitle: string;
  searchRedirectsTitle: string;
  collection: AutocompleteCollection<AutocompleteItem>;
  autocomplete: AutocompleteApi<
    AutocompleteItem,
    React.BaseSyntheticEvent,
    React.MouseEvent,
    React.KeyboardEvent
  >;
  autocompleteState: AutocompleteState<AutocompleteItem> & {
    activeHit: null | AutocompleteItem;
  };
  listLabels: {
    querySuggestionsPlugin: string;
    products: string;
  };
  testId?: string;
  marketplace: string;
};

const { list, itemWrapper, itemContainer, itemTitle, listTitle } = style;

export const ResultList: FC<ResultListProps> = (props) => {
  const {
    searchRedirectsTitle,
    collection,
    autocomplete,
    autocompleteState,
    listLabels,
    testId,
    marketplace,
  } = props;
  const items = collection.items;
  const isRedirect = items[0].label;

  const title = !isRedirect
    ? listLabels[collection.source.sourceId]
    : searchRedirectsTitle;

  return (
    <>
      <h3 className={listTitle}>{title}</h3>
      <ul
        {...autocomplete.getListProps()}
        className={list}
        key={collection.source.sourceId}
        data-source-id={collection.source.sourceId}
        data-testid={testId}
      >
        {items.map((item, idx) => {
          const isSelected =
            autocompleteState.activeHit?.objectID === item.objectID;

          if (collection.source.sourceId === "querySuggestionsPlugin") {
            return (
              <QuerySuggestion
                selected={isSelected}
                href={`/${marketplace}/products/search?q=${item.query}`}
                key={item.query}
              >
                {item.query}
              </QuerySuggestion>
            );
          }

          if (item.isRedirect) {
            return (
              <li
                key={item.__autocomplete_id}
                className={itemWrapper}
                data-selected={isSelected}
                data-test-id="search-redirect-item"
              >
                <a className={itemContainer} href={item.url}>
                  <div className={itemTitle}>{item.label}</div>
                </a>
              </li>
            );
          }

          return (
            <Item
              item={item}
              key={item.objectID}
              position={idx + 1}
              selected={isSelected}
              {...autocomplete.getItemProps({
                item,
                source: collection.source,
              })}
            />
          );
        })}
      </ul>
    </>
  );
};

import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  createAutocomplete,
  type AutocompleteState,
} from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import algoliasearch from "algoliasearch";
import type { SearchResponse } from "@algolia/client-search";
import { reshapeAlgoliaResponse } from "./utils";
import type { AutocompleteItem, AutocompleteProps } from "./types";

export const useAutocomplete = (props: AutocompleteProps) => {
  const { marketplace, apiKey, appId, searchPlaceholder } = props;
  const searchClient = algoliasearch(appId, apiKey);
  const indexName = marketplace?.algoliaIndex;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1040);

  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: `${indexName}_query_suggestions`,
    getSearchParams() {
      return {
        hitsPerPage: 5,
        clickAnalytics: true,
      };
    },
  });

  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem> & { activeHit: null | AutocompleteItem }
  >({
    collections: [],
    activeHit: null,
    completion: null,
    context: {},
    // not used but need to be initialized
    isOpen: true,
    query: "",
    activeItemId: null,
    status: "idle",
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      // TODO make a mini function
      if (window.innerWidth > 1040) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    });

    return () => {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 1040) {
          setIsDesktop(true);
        } else {
          setIsDesktop(false);
        }
      });
    };
  }, []);

  const autocomplete = useMemo(
    () =>
      createAutocomplete<
        AutocompleteItem,
        React.BaseSyntheticEvent,
        React.MouseEvent,
        React.KeyboardEvent
      >({
        onStateChange({ state }) {
          const allHits = state.collections.reduce(
            (acc, collection) => acc.concat(collection.items),
            []
          );

          setAutocompleteState({
            ...state,
            activeHit:
              state.activeItemId !== null ? allHits[state.activeItemId] : null,
          });
        },
        id: searchPlaceholder,
        openOnFocus: true,
        autoFocus: true,
        getSources() {
          return [
            {
              sourceId: "products",
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName,
                      query,
                      params: {
                        hitsPerPage: 5,
                        clickAnalytics: true,
                      },
                    },
                  ],
                  transformResponse({ hits, results }) {
                    const userData = (results as SearchResponse[])?.[0]
                      .userData;

                    if (userData) {
                      return userData[0].redirects;
                    }

                    return hits;
                  },
                });
              },
            },
          ];
        },
        plugins: [querySuggestionsPlugin],
        reshape({ sources }) {
          if (marketplace) {
            return reshapeAlgoliaResponse(sources, indexName, marketplace);
          }
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { setQuery, refresh, getEnvironmentProps } = autocomplete;

  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const viewedProducts = autocompleteState?.collections?.find(
    ({ source }) => source.sourceId === "querySuggestionsPlugin"
  );

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchmove", onTouchMove);
    };
  }, [getEnvironmentProps]);

  useEffect(() => {
    const hasViewedProducts = viewedProducts?.items.length > 0;
    const hasQuery = autocompleteState.query.length > 0;

    if (hasQuery && hasViewedProducts) {
      window.dataLayer?.push({
        event: "Hits Viewed",
        aaHits: viewedProducts.items.map(({ objectID }) => objectID),
      });
    }
    // we disble this because we only want to trigger an event when the actual
    // viewedProducts changes, not when the autocompleteState changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewedProducts]);

  useEffect(() => {
    // necessary evil to detect iOS devices
    // once fully supported we could use https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
    const iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    const html = document.querySelector("html");

    if (iOS) {
      html.classList.add("ios");
    }
  }, []);

  return {
    autocomplete,
    autocompleteState,
    inputRef,
    formRef,
    panelRef,
    setQuery,
    refresh,
    indexName,
    isDesktop,
  };
};

import { useEffect, useState } from "react";
import type { FC } from "react";
import type { AutocompleteItem } from "../types";
import style from "../Autocomplete.module.css";
import { Highlight } from "./Highlight";

export type ItemProps = {
  item: AutocompleteItem;
  imgURL?: string;
  position: number;
  selected: boolean;
};

const { itemWrapper, itemContainer, itemContent, itemTitle } = style;

export const Item: FC<ItemProps> = (props) => {
  const { item, position, selected, ...algoliaItemProps } = props;
  const [isSelected, setIsSelected] = useState(false);

  // (*) Using the effect to make sure we register the
  // correct state change when algolia updates the selected item.
  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <li
      className={itemWrapper}
      {...algoliaItemProps}
      data-insights-object-id={item.objectID}
      data-insights-position={position}
      data-insights-query-id={item.__autocomplete_queryID}
      data-selected={isSelected}
      data-testid="autocomplete-item"
      onClick={(e: React.MouseEvent) => {
        // stop the defocus on the algolia input so dropdown stays open
        e.stopPropagation();
      }}
      // (*) Removes the selected color when the mouse leaves the item
      // This is needed because the algolia autocomplete leaves the aria-selected
      // attribute on the item even when the mouse leaves the item.
      // This will remove the color without interfering with any of the aria attributes
      // that algolia sets.
      onMouseLeave={() => {
        setIsSelected(false);
      }}
    >
      <a
        className={itemContainer}
        href={item.url}
        data-testid="autocomplete-item-link"
      >
        <div className={itemContent}>
          <div className={itemTitle}>
            <Highlight hit={item} attribute="title" />
          </div>
        </div>
      </a>
    </li>
  );
};

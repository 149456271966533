import { getLocaleUrl } from "@wbly/common";
import type {
  AutocompleteReshapeSource,
  ReshapeParams,
} from "@algolia/autocomplete-core";
import type { AutocompleteItem } from "./types";
import {
  ALGOLIA_PARAM_QUERY,
  ALGOLIA_PARAM_SKU,
  ALGOLIA_PARAM_INDEX,
} from "./algolia-analytics";

type Marketplace = {
  identifier: string;
};

export function reshapeAlgoliaResponse(
  sources:
    | AutocompleteReshapeSource<AutocompleteItem>[]
    | ReshapeParams<AutocompleteItem>[],
  indexName: string,
  marketplace: Marketplace
) {
  return sources.map((source) => {
    if (source.sourceId !== "products") {
      return source;
    }

    // reshape the items to add a localised URL with params to use for
    // form submit and also on the item
    const reshapedItems = source.getItems().map((item: AutocompleteItem) => {
      if (item.prismicProductSlug) {
        const params = new URLSearchParams({
          [ALGOLIA_PARAM_QUERY]: item.__autocomplete_queryID,
          // This objectID matches the solidus master sku
          [ALGOLIA_PARAM_SKU]: item.objectID,
          [ALGOLIA_PARAM_INDEX]: indexName,
        });

        const url = getLocaleUrl(
          marketplace.identifier,
          `/personalized-products/${
            item.prismicProductSlug
          }?${params.toString()}`
        );

        return {
          ...item,
          url,
        };
      }

      // TODO agree on a property that can be used for redirects
      // eg. type: redirect
      if (item.label) {
        const redirect = {
          label: item.label,
          url: getLocaleUrl(marketplace.identifier, item.url),
          // used to highlight item in the list
          objectID: item.url,
          isRedirect: true,
        };

        return redirect;
      }
    });

    return {
      ...source,
      getItems() {
        return reshapedItems;
      },
    };
  });
}

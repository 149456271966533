import type { FC } from "react";
import style from "../Autocomplete.module.css";

type ItemProps = {
  imgURL?: string;
  children: string;
  selected: boolean;
  href: string;
};

const { itemWrapper, itemContainer, itemTitle } = style;

export const QuerySuggestion: FC<ItemProps> = (props) => {
  const { children, selected, href } = props;

  return (
    <li
      data-selected={selected}
      className={itemWrapper}
      data-testid="query-suggestion-item"
    >
      <a
        className={itemContainer}
        href={href}
        data-testid="query-suggestion-link"
      >
        <div className={itemTitle}>{children}</div>
      </a>
    </li>
  );
};

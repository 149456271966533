import { useCallback, useEffect } from "react";
import { iconsaxX } from "@wbly/tokens/icon";
import * as Form from "@wbly/ui/Form";
import { Input } from "@wbly/ui/Input";
import { Icon } from "@wbly/ui";
import { getLocaleUrl } from "@wbly/common";
import { useStore } from "@nanostores/react";
import { Close, SearchIcon } from "../Header/Icons";
import { $searchPanel, triggerSearchPanel } from "../../stores/ui.store";
import type { AutocompleteProps } from "./types";
import { useAutocomplete } from "./useAutocomplete";
import style from "./Autocomplete.module.css";
import { ResultList } from "./components/ResultList";

const {
  wrapper,
  container,
  panelClose,
  listTitle,
  listMessage,
  listHeader,
  input,
  inputWrapper,
  closeContainer,
  suffix,
  panelWrapper,
  panelContent,
} = style;

export function Autocomplete(props: AutocompleteProps) {
  const {
    autocomplete,
    autocompleteState,
    inputRef,
    formRef,
    panelRef,
    setQuery,
    refresh,
    indexName,
    isDesktop,
  } = useAutocomplete(props);

  const { isOpen } = useStore($searchPanel);

  const listLabels = {
    querySuggestionsPlugin: props.searchSuggestionsTitle,
    products: props.searchTitle,
  };

  const handleKeySubmit = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (autocompleteState.activeHit?.url) {
        // it's a product hit so go to the url
        window.location.href = autocompleteState.activeHit.url;
      } else if (
        autocompleteState.query?.length !== 0 &&
        // collections[0] will always be empty if the query
        // is a redirect e.g. contact, delivery, etc
        autocompleteState.collections[1]?.items[0]?.isRedirect
      ) {
        // it's a redirect, go to the url
        window.location.href = autocompleteState.collections[1]?.items[0]?.url;
      } else {
        // it's a completion querySuggestion so submit the form
        formRef.current.submit();
      }
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [autocompleteState.activeHit, autocompleteState.query]
  );

  const action =
    // check the user made a query and the result is not a redirect
    !autocompleteState.collections[1]?.items[0]?.isRedirect
      ? // send user to the matching range page
        getLocaleUrl(props.marketplace.identifier, `/products/search`)
      : // if it is a redirect, send user to that url
        getLocaleUrl(
          props.marketplace.identifier,
          `${autocompleteState.collections[1]?.items[0]?.url}`
        );

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
      return;
    }

    inputRef.current.blur();
  }, [isOpen, inputRef]);

  return (
    <>
      <div className={wrapper} data-open={isOpen}>
        <div
          className={container}
          data-insights-index={indexName}
          data-open={isOpen}
          {...autocomplete.getRootProps()}
        >
          <Form.Root
            ref={formRef}
            name="search"
            layout="inline"
            method="get"
            contentEditable={false}
            {...autocomplete.getFormProps({
              inputElement: inputRef.current,
              action: action,
              onKeyDownCapture: (e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  handleKeySubmit(e);
                }
              },
              onBlur: (e: React.FocusEvent) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
              },
            })}
          >
            <div className={closeContainer}>
              <button
                onClick={(e) => {
                  triggerSearchPanel("close");
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }}
              >
                <Close />
              </button>
            </div>
            <Form.Field className={inputWrapper}>
              <div>
                <label {...autocomplete.getLabelProps()}>
                  <button
                    id="search-button"
                    type="submit"
                    title="Search"
                    tabIndex={-1}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();

                      formRef.current.submit();
                    }}
                  >
                    <SearchIcon />
                  </button>
                </label>
              </div>
              <Input
                className={input}
                ref={inputRef}
                name="q"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                maxLength={512}
                type="search"
                onBlur={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }}
                {...autocomplete.getInputProps({
                  onChange: (event: React.BaseSyntheticEvent) => {
                    setQuery(event.currentTarget.value);
                    refresh();
                  },
                  placeholder: props.searchPlaceholder,
                  inputElement: inputRef.current,
                })}
              />

              <div className={suffix}>
                <label {...autocomplete.getLabelProps()}>
                  <button
                    id="search-button"
                    type="submit"
                    title="Search"
                    tabIndex={-1}
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();

                      formRef.current.submit();
                    }}
                  >
                    <SearchIcon />
                  </button>
                </label>
              </div>
            </Form.Field>
          </Form.Root>

          <button
            type="reset"
            id="clear-button"
            className={panelClose}
            aria-label="Close dropdown"
            onClick={(e) => {
              triggerSearchPanel("close");
              e.stopPropagation();
              setQuery("");
            }}
          >
            <Icon href={iconsaxX} />
          </button>
        </div>
      </div>

      {(isOpen || !isDesktop) && (
        <div
          ref={panelRef}
          {...autocomplete.getPanelProps()}
          className={panelWrapper}
          data-open={isOpen}
        >
          <div className={panelContent}>
            <div className={listHeader}>
              {autocompleteState.query &&
                autocompleteState.collections.every(
                  ({ items }) => items.length === 0
                ) && (
                  <p className={`${listMessage} ${listTitle}`}>
                    {props.searchNoResults}
                  </p>
                )}
            </div>

            {autocompleteState.collections.length > 0 &&
              autocompleteState.collections.map((collection) => {
                if (collection.items.length === 0) {
                  return null;
                }

                return (
                  <ResultList
                    listTitle={listLabels[collection.source.sourceId]}
                    searchRedirectsTitle={props.searchRedirectsTitle}
                    key={collection.source.sourceId}
                    collection={collection}
                    autocomplete={autocomplete}
                    autocompleteState={autocompleteState}
                    listLabels={listLabels}
                    marketplace={props.marketplace.identifier}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
